import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';

const NotAvailable = () => (
  <div className="container">
    <div className="not-available">
      <h2>
        <I18nText id="ibe.not_available.title" returnStringOnly={true} />
      </h2>
      <p>
        <I18nText id="ibe.not_available.subtitle" returnStringOnly={true} />
      </p>
    </div>
  </div>
);

export default NotAvailable;
