import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';

const BookingOption = ({ options }) =>
  options.map(option => {
    const { iconName, text, buttonLabel, onClick, children, disabled } = option;

    return (
      <div key={buttonLabel} className="booking-modal__option">
        <div className="booking-modal__option-row">
          <Icon name={iconName} showBGColor={true} size="large" />
        </div>
        <div className="booking-modal__option-row">{text}</div>
        <div className="booking-modal__option-row">
          <Button
            version="v2"
            size="small"
            label={buttonLabel}
            primary={true}
            disabled={disabled}
            onClick={onClick}
          />
        </div>
        {children && <div className="booking-modal__option-row">{children}</div>}
      </div>
    );
  });

BookingOption.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      disabled: PropTypes.bool,
      onClick: PropTypes.func.isRequired,
      children: PropTypes.string,
    }),
  ).isRequired,
};

export default BookingOption;
