const shouldShowPreselectTravelerButton = (
  loggedInAsTravelArranger,
  loggedOutWithEmptyCartAndForm,
  forceAdvancedSearch,
  laymanMode,
  travelerLoggedIn,
) => {
  const isEligibleForPreselect = loggedInAsTravelArranger || loggedOutWithEmptyCartAndForm;
  const isLaymanTraveler = laymanMode && travelerLoggedIn;

  return (isEligibleForPreselect && !forceAdvancedSearch) || isLaymanTraveler;
};

export default shouldShowPreselectTravelerButton;
