import React, { useEffect, useState, useRef, Fragment } from 'react';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import routes from 'agentRoutes';
import PropTypes from 'prop-types';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import EmptyTableContent from 'sharedWebpack/EmptyTableContent';
import Deal from '../DealsCard';

import '../styles.scss';

const DealsList = ({ category }) => {
  const [deals, setDeals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const componentMounted = useRef(true);

  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useQueryParams({
    category: withDefault(StringParam, category),
  });

  const fetchDeals = apiToken => {
    setIsLoading(true);
    setQuery(
      {
        category,
      },
      'replaceIn',
    );

    const searchCategory = category === 'all' ? null : category;

    httpClient
      .get(
        routes.public.deals({
          'categories[]': searchCategory,
        }),
        { cancelToken: apiToken },
      )
      .then(response => {
        if (componentMounted.current) {
          const { data } = response;
          setDeals(data.deals);
          setIsLoading(false);
        }
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }
        throw error;
      });
  };

  const addToFavourite = async id => {
    const { data } = await httpClient.put(routes.api.deals.favourite({ dealId: id }));

    if (data.id) {
      fetchDeals();
    }
  };

  const deleteFromFavourite = async id => {
    const { data } = await httpClient.delete(routes.api.deals.favourite({ dealId: id }));

    if (data.id) {
      fetchDeals();
    }
  };

  useEffect(() => {
    const getDealsCatalogueApiToken = generateSourceToken();
    componentMounted.current = true;
    fetchDeals(getDealsCatalogueApiToken.token);

    return () => {
      getDealsCatalogueApiToken.cancel();
      componentMounted.current = false;
    };
  }, [category]);

  const dealShowPageUrl = id => {
    const url = routes.public.deal;
    return url({
      id,
    });
  };

  return (
    <Fragment>
      {isLoading ? (
        <ContentLoaderPlaceholder
          className="top-deals__content-loader"
          numberOfLines={3}
          showBackground={false}
        />
      ) : (
        <div className="deals-list">
          {deals.map(deal => (
            <Deal
              id={deal.id}
              name={deal.name}
              amount={deal.adAmount}
              currency={deal.currency}
              imageUrl={deal.imageUrl}
              detailsUrl={dealShowPageUrl(deal.id)}
              addToFavourite={value => addToFavourite(value)}
              deleteFromFavourite={value => deleteFromFavourite(value)}
              favourite={deal.favourite}
              teaser={deal.teaser}
              itemTypes={deal.itemTypes}
              currentUserId={deal.currentUserId}
              favouriteCount={deal.favouriteCount}
              scope={{ showFavouriteCount: true }}
            />
          ))}
        </div>
      )}
      {!isLoading && !deals.length && <EmptyTableContent />}
    </Fragment>
  );
};

DealsList.propTypes = {
  category: PropTypes.string.isRequired,
};

export default withQueryParamsProvider(DealsList);
