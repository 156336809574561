import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SidePanel } from '@wtag/react-comp-lib';
import useBodyScrollLock from 'sharedWebpack/useBodyScrollLock';
import WellDevBookingComponentContainer from '../AddNewCreditCard/WellDevBookingComponentContainer';
import { NEW_TOKEN_KEY } from '../helpers/authenticationButtonLabelOptions';
import { ccPanelVisible } from '../helpers/ccvPayment';
import {
  byTokenShape,
  uniqueCCTokenShape,
  bookingComponentRefByTokenShape,
  bookingRequestsEncodedByTokenShape,
  creditCardItemShape,
} from '../shapes';

const UniqueCCTokenPanels = ({
  uniqueCCTokens,
  allCreditCards,
  bookingComponentRefByToken,
  bookingRequestsEncodedByToken,
  stringifiedThreeDsStatuses,
  cardStoredStatusByToken,
  bookingRequestsEncoded,
  currentAuthenticationRequest,
  threeDsStatusCallback,
  setCardDataAvailableByToken,
  errorAlertByToken,
  successAlertByToken,
  primaryButtonText,
  primaryButtonDisabled,
  onPrimaryButtonClick,
  togglePanel,
  forPostBooking,
  openPanelParam,
  useTogglePanelCallback,
  onSidePanelClick,
  onSidePanelClose,
  ...otherProps
}) => {
  const [sidePanelTitleByToken, setSidePanelTitleByToken] = useState({});
  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock();

  const handleSidePanelToggle = (ccToken, index, togglePanelCallback) => {
    if (useTogglePanelCallback) {
      togglePanel(ccToken, index);
    }

    togglePanelCallback();
  };

  const getSidePanelTitleByToken = ccToken => {
    if (ccToken) {
      return sidePanelTitleByToken[ccToken];
    }

    return sidePanelTitleByToken[NEW_TOKEN_KEY];
  };

  useEffect(() => {
    if (/^cc_panel(_[0-9])?$/.test(openPanelParam)) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }

    return () => {
      unlockBodyScroll();
    };
  }, [openPanelParam]);

  return (
    <Fragment>
      {uniqueCCTokens.map((ccToken, index) => (
        <SidePanel
          title={getSidePanelTitleByToken(ccToken)}
          body={
            <WellDevBookingComponentContainer
              toggleModal={() => togglePanel(ccToken, index)}
              ccToken={ccToken}
              cardStoredStatusByToken={cardStoredStatusByToken}
              bookingComponentRef={bookingComponentRefByToken[ccToken]}
              bookingRequestsEncoded={bookingRequestsEncodedByToken[ccToken]}
              toBeAuthenticatedReq={currentAuthenticationRequest}
              threeDsStatusCallback={threeDsStatusCallback}
              setCardDataAvailableByToken={setCardDataAvailableByToken}
              errorAlertByToken={errorAlertByToken}
              successAlertByToken={successAlertByToken}
              allCreditCards={allCreditCards}
              primaryButtonText={primaryButtonText}
              primaryButtonDisabled={primaryButtonDisabled}
              onPrimaryButtonClick={onPrimaryButtonClick}
              setSidePanelTitleByToken={setSidePanelTitleByToken}
              sidePanelTitle={getSidePanelTitleByToken(ccToken)}
              forPostBooking={forPostBooking}
              {...otherProps}
            />
          }
          isOpened={ccPanelVisible(ccToken, index, openPanelParam)}
          onClick={() => handleSidePanelToggle(ccToken, index, onSidePanelClick)}
          onClose={() => handleSidePanelToggle(ccToken, index, onSidePanelClose)}
        />
      ))}
    </Fragment>
  );
};

UniqueCCTokenPanels.defaultProps = {
  useTogglePanelCallback: false,
  cardStoredStatusByToken: {},
  forPostBooking: false,
  setCardDataAvailableByToken: () => {},
  hideSidePanel: () => {},
};

UniqueCCTokenPanels.propTypes = {
  togglePanel: PropTypes.func.isRequired,
  allCreditCards: PropTypes.arrayOf(creditCardItemShape).isRequired,
  uniqueCCTokens: PropTypes.arrayOf(uniqueCCTokenShape).isRequired,
  cardStoredStatusByToken: PropTypes.shape(byTokenShape),
  forPostBooking: PropTypes.bool,
  setCardDataAvailableByToken: PropTypes.func,
  bookingRequestsEncoded: PropTypes.bool.isRequired,
  currentAuthenticationRequest: PropTypes.bool.isRequired,
  threeDsStatusCallback: PropTypes.func.isRequired,
  errorAlertByToken: PropTypes.bool.isRequired,
  successAlertByToken: PropTypes.bool.isRequired,
  primaryButtonText: PropTypes.string.isRequired,
  primaryButtonDisabled: PropTypes.bool.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  openPanelParam: PropTypes.string.isRequired,
  ccPanelVisible: PropTypes.bool.isRequired,
  setShowTestPassedConfirmation: PropTypes.func.isRequired,
  stringifiedThreeDsStatuses: PropTypes.string.isRequired,
  bookingComponentRefByToken: PropTypes.shape(bookingComponentRefByTokenShape).isRequired,
  bookingRequestsEncodedByToken: PropTypes.shape(bookingRequestsEncodedByTokenShape).isRequired,
  setSkipAuthenticationStatusesByToken: PropTypes.func.isRequired,
  useTogglePanelCallback: PropTypes.bool,
  onSidePanelClick: PropTypes.func.isRequired,
  onSidePanelClose: PropTypes.func.isRequired,
  hideSidePanel: PropTypes.func,
};

export default UniqueCCTokenPanels;
