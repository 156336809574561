import React from 'react';
import PropTypes from 'prop-types';
import PayLaterItem from '../../PayLaterItem';
import './styles.scss';

const PayLaterInfo = ({ disableBooking, payLaterItemsToBeQuoted }) => {
  if (!disableBooking && payLaterItemsToBeQuoted.length === 0) {
    return null;
  }

  const renderSupportStatusMessage = () => {
    if (disableBooking) {
      return I18n.t('components.ibe.booking.pay_later.all_not_supported');
    }

    if (payLaterItemsToBeQuoted.length > 0) {
      return I18n.t('components.ibe.booking.pay_later.some_not_supported');
    }

    return null;
  };

  const supportMessage = renderSupportStatusMessage();

  return (
    <div className="pay-later-info">
      <div className="pay-later-info__border">
        {supportMessage && (
          <div className="pay-later-info__warning-text-container">{supportMessage}</div>
        )}

        {!disableBooking && payLaterItemsToBeQuoted.length > 0 && (
          <div className="pay-later-info__items">
            {payLaterItemsToBeQuoted.map(item => (
              <PayLaterItem key={item.itemTitle} item={item} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

PayLaterInfo.defaultProps = {
  payLaterItemsToBeQuoted: [],
};

PayLaterInfo.propTypes = {
  disableBooking: PropTypes.bool.isRequired,
  payLaterItemsToBeQuoted: PropTypes.arrayOf(
    PropTypes.shape({
      itemTitle: PropTypes.string.isRequired,
    }),
  ),
};

export default PayLaterInfo;
