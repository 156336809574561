import React, { useEffect, useState } from 'react';
import httpClient from 'agentHTTPClient';
import PropTypes from 'prop-types';
import DateTime from 'sharedWebpack/DateTime';
import Avatar from '@wtag/rcl-avatar';
import SanitizedHTML from 'sharedWebpack/SanitizedHTML';

import './styles.scss';

const StaticPage = ({ url, isBlog }) => {
  const [staticPageDetails, setStaticPageDetails] = useState();

  const fetchStaticPageDetails = async () => {
    const { data } = await httpClient.get(url);

    setStaticPageDetails(data.staticPage);
  };

  const renderTitle = () => {
    if (isBlog) {
      return staticPageDetails.name;
    }
    if (staticPageDetails.type === 'jobs') {
      return <div className="static-pages__title--with-padding">{staticPageDetails.name}</div>;
    }
    return (
      <div className="static-pages__title--with-padding">
        {I18n.t(staticPageDetails.type, { scope: 'public.static_pages' })}
      </div>
    );
  };

  useEffect(() => {
    fetchStaticPageDetails();
  }, []);

  return (
    <div className="static-pages">
      {staticPageDetails && (
        <img
          src={staticPageDetails.featureImage}
          className="static-pages__title__image"
          alt="static_page_image"
        />
      )}

      <div className="static-pages__details">
        {isBlog && staticPageDetails && (
          <div className="static-pages__author">
            <div className="static-pages__author-avatar">
              <Avatar
                size="tiny"
                firstName={staticPageDetails.authorFirstName}
                lastName={staticPageDetails.authorLastName}
                src={staticPageDetails.authorFeatureImage}
              />
            </div>

            <div className="static-pages__author-name">{staticPageDetails.authorName}</div>
          </div>
        )}
        <div className="static-pages__title">{staticPageDetails && renderTitle()}</div>

        <div className="static-pages__title__last-updated">
          {staticPageDetails && (
            <React.Fragment>
              <span>{I18n.t('public.static_pages.last_updated')}</span>

              <DateTime dateTime={staticPageDetails.lastUpdated} format="short" />
            </React.Fragment>
          )}
        </div>
        <div className="static-pages__html-content">
          {staticPageDetails && <SanitizedHTML html={staticPageDetails.content} />}
        </div>
      </div>
    </div>
  );
};

StaticPage.defaultProps = {
  isBlog: false,
};

StaticPage.propTypes = {
  url: PropTypes.string.isRequired,
  isBlog: PropTypes.bool,
};

export default StaticPage;
