import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@wtag/react-comp-lib';

const ButtonWithSpinner = ({ isLoading, text }) => (
  <Fragment>
    {text}
    {isLoading && <Spinner size="tiny" bgColor="neutral" />}
  </Fragment>
);

ButtonWithSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default ButtonWithSpinner;
