/* global I18n */
import React from 'react';
import Alert from 'sharedWebpack/Alert';
import { CheckBox, PhoneNumber } from '@wtag/react-comp-lib';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import httpV3Client from 'agentHTTPV3Client';
import routes from 'agentRoutes';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import Radio from '@wtag/rcl-radio';
import Button from '@wtag/rcl-button';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import ProductIcon from 'sharedWebpack/ProductIcon';
import fetchPhoneCountryCodes from 'sharedWebpack/fetchPhoneCountryCodes';
import paymentMethodName from '../../IBE/Payment/helpers/paymentMethodName';
import FormattedAmount from '../../Amount';
import CountrySelectorContainer from '../../IBE/components/CountrySelector';
import reduxFormShape from '../../IBE/Payment/shapes/reduxForm';
import { isChannelPresent } from '../../IBE/lib/validations/commonBookingValidations';
import { NEW_TOKEN_KEY } from '../helpers/authenticationButtonLabelOptions';
import customStyles from './customStyles';
import { TYPE_SEAT } from '../../helpers/itemTypes';
import findLabelValue from '../../IBE/helpers/findLabelValue';
import './style.scss';

class WellDevBookingComponent extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      billingAddress: null,
      ccvError: false,
      validationError: false,
      cardTypeValidationError: false,
      challengeLoading: false,
      isEmptyFieldSelected: false,
      isTravellerAddressSelected: true,
      isAgencyAddressSelected: false,
      isTravellerChecked: true,
      tenantSetting: null,
      error: null,
      phoneCountryCodes: [],
    };
  }

  componentDidMount() {
    this.addOwnersBillingAddressAsBillingAddress();
    this.getPhoneCountryCodes();
  }

  componentDidUpdate(prevProps) {
    const propChanged =
      this.props.ownersBillingAddress.city !== prevProps.ownersBillingAddress.city;
    const addressFormGotReset =
      this.props.ownersBillingAddress.city &&
      !this.props.fields.cardholderAddress.city.value &&
      this.state.isTravellerAddressSelected &&
      !this.state.isAgencyAddressSelected &&
      !this.state.isEmptyFieldSelected;

    if (propChanged || addressFormGotReset) {
      this.addOwnersBillingAddressAsBillingAddress();
    }
  }

  onSubmit = values => {
    if (this.props.valid) {
      this.setState({
        billingAddress: values.cardholderAddress,
        isEmptyFieldSelected: false,
        isAgencyAddressSelected: false,
      });
    }
  };

  getPhoneCountryCodes = async () => {
    const data = await fetchPhoneCountryCodes();

    this.setState({ phoneCountryCodes: data });
  };

  setItemProperties = (item, typeKey, nameKey, priceKey, currencyKey) => ({
    currentItemType: item[typeKey],
    currentItemName: item[nameKey],
    currentItemPrice: item[priceKey],
    currentItemCurrency: item[currencyKey],
  });

  addOwnersBillingAddressAsBillingAddress = () => {
    this.handleAddresses(
      this.props.ownersBillingAddress.city,
      this.props.ownersBillingAddress.street,
      this.props.ownersBillingAddress.state,
      this.props.ownersBillingAddress.country,
      this.props.ownersBillingAddress.zip,
      this.props.ownersBillingAddress.email,
      this.props.ownersBillingAddress.phoneCountryCode,
      this.props.ownersBillingAddress.phoneNumber,
    );
  };

  resetBillingAddress = () => {
    this.handleAddresses('', '', '', '', '', '', '', '');
  };

  fetchTenantSettings = async () => {
    try {
      const { data } = await httpV3Client.get(routes.api.settings());
      this.setState({ tenantSetting: data }, () => {
        const tenantSetting = this.state.tenantSetting;
        this.handleAddresses(
          tenantSetting.city,
          tenantSetting.street,
          tenantSetting.state,
          tenantSetting.countryCode,
          tenantSetting.zipCode,
          tenantSetting.email,
          tenantSetting.phoneCountryCode,
          tenantSetting.phone,
        );
      });
    } catch ({ response }) {
      this.setState({
        error: response.data.error,
      });
    }
  };

  discardForm = () => {
    this.setState({
      billingAddress: null,
      ccvError: false,
      validationError: false,
      cardTypeValidationError: false,
    });
  };

  updateCcvErrorStatus = status => {
    this.setState({
      ccvError: status,
    });
  };

  updateValidationErrorStatus = status => {
    this.setState({
      validationError: status,
    });
  };

  saveCreditCardInBackendAndSetStatus = (data, callbackParams) => {
    this.updateCcvErrorStatus(false);
    this.updateValidationErrorStatus(false);
    this.props
      .saveCreditCardInBackend(
        this.props.cartId,
        data,
        this.state.billingAddress,
        callbackParams,
        this.props.setCardDataAvailableByToken,
      )
      .then(() => {
        this.props.toggleModal();
      });
  };

  validateCardType = data => {
    if (this.props.currentTab === 0) {
      return this.props.commonCreditCardTypes.includes(data.type);
    }
    return this.props.availableCreditCardTypes.includes(data.type);
  };

  creditCardTokenHandler = data => {
    const callbackParams = this.props.callbackParams || this.context.callbackParams;

    if (data && data.status === 'token.create.successful') {
      if (
        !isChannelPresent('auto_europe', this.props.itemChannels) ||
        data.fullname.toLowerCase() === this.props.fullName.toLowerCase()
      ) {
        if (this.props.forPostBooking) {
          this.saveCreditCardInBackendAndSetStatus(data, callbackParams);
        } else if (this.validateCardType(data)) {
          this.setState({ cardTypeValidationError: false });
          this.saveCreditCardInBackendAndSetStatus(data, callbackParams);
        } else {
          this.setState({ cardTypeValidationError: true });
        }
      } else {
        this.updateValidationErrorStatus(true);
      }
    } else if (data && data.status === 'token.create.failed') {
      this.updateCcvErrorStatus(true);
    }
  };

  threeDSAuthenticationStatusHandler = data => {
    if (data.threeDsAuthenticationStatus === 'threeds2.authentication.initiated') {
      this.setState({ challengeLoading: true });
    } else if (data.threeDsAuthenticationStatus === 'threeds2.authentication.challenge_loaded') {
      this.setState({ challengeLoading: false });
    } else if (data.threeDsAuthenticationStatus === 'threeds2.authentication.successful') {
      this.props.threeDsStatusCallback(this.props.ccToken, data.encodedRequest, true);
    } else if (data.threeDsAuthenticationStatus === 'threeds2.authentication.unsuccessful') {
      this.props.threeDsStatusCallback(this.props.ccToken, data.encodedRequest, false);
    }
  };

  handleAddresses = (city, street, state, country, zip, email, phoneCountryCode, phoneNumber) => {
    this.props.fields.cardholderAddress.city.onChange({
      value: city || '',
    });
    this.props.fields.cardholderAddress.street.onChange({
      value: street || '',
    });
    this.props.fields.cardholderAddress.state.onChange({
      value: state || '',
    });
    this.props.fields.cardholderAddress.country.onChange({
      value: country || '',
    });
    this.props.fields.cardholderAddress.zip.onChange({
      value: zip || '',
    });
    this.props.fields.cardholderAddress.email.onChange({
      value: email || '',
    });
    this.props.fields.cardholderAddress.phoneCountryCode.onChange({
      value: phoneCountryCode || '',
    });
    this.props.fields.cardholderAddress.phoneNumber.onChange({
      value: phoneNumber || '',
    });
  };

  findItem = (items, authReq) => items.find(item => item.encodedPaymentRequestPayload === authReq);

  render() {
    const WellDevBooking = window.WellDevCreditCardActions;
    let content;
    let maskedNumber;
    let cardType;
    let totalAuthCount;
    let currentAuthIndex;
    let itemProperties = {};
    let cardDetails;
    const withCreditCardToken = !!this.props.ccToken;
    const laymanMode = this.props.laymanMode === true || this.context.laymanMode === true;
    const ccvURL = this.props.ccvURL || this.context.ccvURL;
    const ccvPublicToken = this.props.ccvPublicToken || this.context.ccvPublicToken;
    const tenantIdentifier = this.props.tenantIdentifier || this.context.tenantIdentifier;
    const {
      fields: {
        cardholderAddress: {
          city,
          country,
          street,
          state,
          zip,
          email,
          phoneCountryCode,
          phoneNumber,
        },
      },
      submitting,
      handleSubmit,
      touched,
    } = this.props;

    if (withCreditCardToken) {
      cardDetails = this.props.allCreditCards.find(card => card.token === this.props.ccToken);
    }
    if (
      withCreditCardToken &&
      cardDetails &&
      this.props.bookingRequestsEncoded &&
      this.props.toBeAuthenticatedReq
    ) {
      maskedNumber = cardDetails.maskedNumber;
      cardType = cardDetails.cardType;
      totalAuthCount = this.props.bookingRequestsEncoded.length;
      const currentAuthReq = this.props.toBeAuthenticatedReq;
      currentAuthIndex = this.props.bookingRequestsEncoded.indexOf(currentAuthReq) + 1;
      const { cartItems, orderItems, postBookingContent } = this.props;

      if (cartItems && cartItems.length > 0) {
        const currentItem = this.findItem(cartItems, currentAuthReq);

        if (currentItem) {
          itemProperties = this.setItemProperties(
            currentItem,
            'journeyElementType',
            'itemTitle',
            'grossTotalIncludingSubItems',
            'currency',
          );
        }
      } else if (orderItems && orderItems.length > 0) {
        const currentItem = this.findItem(orderItems, currentAuthReq);

        if (currentItem) {
          itemProperties = this.setItemProperties(
            currentItem,
            'journeyElementType',
            'title',
            'grossPriceWithOpenSubItems',
            'currency',
          );
        }
      } else if (postBookingContent && postBookingContent.items.length > 0) {
        const currentItem = this.findItem(postBookingContent.items, currentAuthReq);

        if (currentItem) {
          itemProperties = this.setItemProperties(
            { type: TYPE_SEAT, ...currentItem },
            'type',
            'title',
            'totalPrice',
            'currency',
          );
        }
      }
    }

    if (WellDevBooking == null) {
      content = (
        <div className="credit-card-details credit-card-details--remove-top-padding">
          <h3>{I18n.t('components.credit_card.error')}</h3>
        </div>
      );
    } else if (this.state.billingAddress || withCreditCardToken) {
      const header = withCreditCardToken
        ? I18n.t('ibe.payment.credit_card.auth_step')
        : I18n.t('ibe.payment.credit_card.step_2');
      let token;
      if (withCreditCardToken) {
        token = this.props.ccToken;
      } else {
        token = NEW_TOKEN_KEY;
      }
      if (header !== this.props.sidePanelTitle) {
        this.props.setSidePanelTitleByToken(prevState => {
          const newState = Object.assign({}, prevState);
          newState[token] = header;

          return newState;
        });
      }

      const getAlertText = () => {
        if (this.state.cardTypeValidationError) {
          return I18n.t('ibe.payment.credit_card.card_not_supported');
        } else if (this.state.validationError) {
          return I18n.t('components.ibe.car.validations.card_holder_name');
        }

        return I18n.t('ibe.payment.credit_card.error');
      };

      content = (
        <React.Fragment>
          <div className="credit-card-details__iframe">
            <Alert
              className="credit-card-details--add-margin"
              isBackgroundVisible={true}
              isVisible={
                this.state.validationError ||
                this.state.ccvError ||
                this.state.cardTypeValidationError
              }
              type="warning"
              hideClose={true}
            >
              {getAlertText()}
            </Alert>
            {withCreditCardToken ? (
              <div className="credit-card-details__iframe-details">
                <div className="credit-card-details__iframe-details-header">
                  {I18n.t('ibe.payment.credit_card.auth_sub_header')}
                </div>
                <div className="col-grid col-bleed direction-row align-center credit-card-details__iframe-details-card">
                  <div className="credit-card-details__iframe-details-card-icon">
                    <Icon name={paymentMethodName(cardType, laymanMode)} size="medium" />
                  </div>
                  {maskedNumber}
                </div>
                {this.props.bookingRequestsEncoded && (
                  <React.Fragment>
                    <div className="credit-card-details__iframe-details-card-info">
                      {I18n.t('ibe.payment.credit_card.auth_info', {
                        item_count: this.props.bookingRequestsEncoded.length,
                      })}
                    </div>
                    <div className="credit-card-details__iframe-details-sub-header">
                      {I18n.t('ibe.payment.credit_card.auth_challenge_header', {
                        index: currentAuthIndex,
                        count: totalAuthCount,
                      })}
                    </div>
                    <div className="credit-card-details__iframe-details-item-name">
                      <div className="col-grid col-bleed direction-row align-center">
                        <ProductIcon
                          productType={itemProperties.currentItemType}
                          showBGColor={true}
                          showLabel={true}
                          size="tiny"
                        />
                        <span className="credit-card-details__iframe-details-item-name-text">
                          {` | ${itemProperties.currentItemName}`}
                        </span>
                      </div>
                    </div>
                    <div className="credit-card-details__iframe-details-item-label">
                      {I18n.t('ibe.payment.credit_card.item_name')}
                    </div>
                    <div className="credit-card-details__iframe-details-item-price">
                      <FormattedAmount
                        currency={itemProperties.currentItemCurrency}
                        value={itemProperties.currentItemPrice}
                      />
                    </div>
                    <div className="credit-card-details__iframe-details-item-label">
                      {I18n.t('ibe.payment.credit_card.price')}
                    </div>
                  </React.Fragment>
                )}
              </div>
            ) : (
              <div className="credit-card-details__title">
                {I18n.t('ibe.payment.credit_card.card_info')}
              </div>
            )}
            <div
              className={classNames('credit-card-details__iframe-component', {
                'credit-card-details__iframe-component--hidden':
                  this.props.errorAlertByToken[this.props.ccToken] ||
                  this.props.successAlertByToken[this.props.ccToken],
              })}
            >
              {this.state.challengeLoading && (
                <ContentLoaderPlaceholder
                  numberOfLines={14}
                  showBackground={false}
                  contentWidth="350px"
                />
              )}
              <WellDevBooking
                iframeBaseURL={ccvURL}
                iframeHostURL={window.location.origin}
                merchantApiToken={ccvPublicToken}
                creditCardCreationHandler={this.creditCardTokenHandler}
                threeDSAuthenticationStatusHandler={this.threeDSAuthenticationStatusHandler}
                submitText={I18n.t('components.credit_card.button.label')}
                styles={customStyles}
                iframeHeight="100%"
                creditCardActionRequestsEncoded={this.props.bookingRequestsEncoded}
                creditCardToken={
                  this.props.cardStoredStatusByToken[this.props.ccToken] ? this.props.ccToken : null
                }
                withCreditCardToken={
                  this.props.cardStoredStatusByToken[this.props.ccToken]
                    ? withCreditCardToken
                    : null
                }
                cartId={this.props.cartId}
                ref={this.props.bookingComponentRef}
                tenantIdentifier={tenantIdentifier}
                locale={I18n.locale}
                actionType={this.props.actionType}
                billingAddress={this.state.billingAddress || {}}
              />
            </div>
            <Alert
              type="danger"
              hideClose={true}
              isVisible={this.props.errorAlertByToken[this.props.ccToken]}
            >
              {I18n.t('ibe.payment.three_ds_message.single_test_failed')}
            </Alert>
            <Alert
              type="success"
              hideClose={true}
              isVisible={this.props.successAlertByToken[this.props.ccToken]}
            >
              {I18n.t('ibe.payment.three_ds_message.single_test_passed')}
            </Alert>
            {withCreditCardToken && this.props.bookingRequestsEncoded && (
              <div className="credit-card-details__buttons">
                <Button
                  size="small"
                  version="v2"
                  className=""
                  label={I18n.t(this.props.primaryButtonText, {
                    scope: 'ibe.payment.three_ds_message.button_labels',
                  })}
                  primary={true}
                  disabled={this.props.primaryButtonDisabled}
                  onClick={this.props.onPrimaryButtonClick}
                />
                <Button
                  size="small"
                  version="v2"
                  className=""
                  label={I18n.t('shared.actions.cancel')}
                  primary={false}
                  onClick={this.props.toggleModal}
                />
              </div>
            )}
          </div>
        </React.Fragment>
      );
    } else {
      const header = I18n.t('ibe.payment.credit_card.step_1');
      if (header !== this.props.sidePanelTitle) {
        this.props.setSidePanelTitleByToken(prevState => {
          const newState = Object.assign({}, prevState);
          newState[NEW_TOKEN_KEY] = header;
          return newState;
        });
      }

      const travellerAutofillOptionsForAgent = (
        <React.Fragment>
          <div className="credit-card-details__radio">
            <Radio
              size="huge"
              name="address"
              isChecked={this.state.isTravellerAddressSelected}
              onChange={() => {
                this.setState(
                  {
                    isTravellerAddressSelected: !this.state.isTravellerAddressSelected,
                  },
                  () => {
                    if (this.state.isTravellerAddressSelected) {
                      this.setState({
                        isAgencyAddressSelected: false,
                        isEmptyFieldSelected: false,
                      });
                      this.addOwnersBillingAddressAsBillingAddress();
                    }
                  },
                );
              }}
              label={I18n.t('ibe.payment.credit_card.address.traveller', {
                traveller: this.props.fullName,
              })}
            />
          </div>
          <div className="credit-card-details__radio">
            <Radio
              size="huge"
              name="address"
              isChecked={this.state.isAgencyAddressSelected}
              onChange={() => {
                this.setState(
                  {
                    isAgencyAddressSelected: !this.state.isAgencyAddressSelected,
                  },
                  () => {
                    if (this.state.isAgencyAddressSelected) {
                      this.setState({
                        isTravellerAddressSelected: false,
                        isEmptyFieldSelected: false,
                      });
                      this.fetchTenantSettings();
                    }
                  },
                );
              }}
              label={I18n.t('ibe.payment.credit_card.address.travel_agency')}
            />
          </div>
          <div className="credit-card-details__radio">
            <Radio
              size="huge"
              name="address"
              isChecked={this.state.isEmptyFieldSelected}
              onChange={() => {
                this.setState(
                  {
                    isEmptyFieldSelected: !this.state.isEmptyFieldSelected,
                  },
                  () => {
                    if (this.state.isEmptyFieldSelected) {
                      this.setState({
                        isTravellerAddressSelected: false,
                        isAgencyAddressSelected: false,
                      });
                      this.resetBillingAddress();
                    }
                  },
                );
              }}
              label={I18n.t('ibe.payment.credit_card.address.new_address')}
            />
          </div>
        </React.Fragment>
      );

      const travellerAutofillOptionsForTraveller = (
        <div className="credit-card-details__checkbox">
          <CheckBox
            label={I18n.t('ibe.payment.credit_card.address.traveller', {
              traveller: this.props.fullName,
            })}
            checked={this.state.isTravellerChecked}
            onChange={() => {
              this.setState(
                {
                  isTravellerChecked: !this.state.isTravellerChecked,
                  isEmptyFieldSelected: !this.state.isEmptyFieldSelected,
                },
                () => {
                  if (this.state.isTravellerChecked) {
                    this.addOwnersBillingAddressAsBillingAddress();
                  } else {
                    this.resetBillingAddress();
                  }
                },
              );
            }}
            size="medium"
          />
        </div>
      );

      content = (
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="credit-card-details__title">
            {I18n.t('ibe.payment.credit_card.billing_info')}
          </div>
          {!this.context.laymanMode
            ? travellerAutofillOptionsForAgent
            : travellerAutofillOptionsForTraveller}

          <div className="credit-card-details__form">
            <div className="credit-card-details__field-street">
              <Input
                label={I18n.t('ibe.payment.credit_card.address.street')}
                placeholder={I18n.t('ibe.payment.credit_card.address.street_placeholder')}
                size="tiny"
                touched={touched}
                required={true}
                {...street}
              />
            </div>
            <div className="credit-card-details__field-city-state--wrapper">
              <div className="credit-card-details__field-city">
                <Input
                  label={I18n.t('ibe.payment.credit_card.address.city')}
                  touched={touched}
                  placeholder={I18n.t('ibe.payment.credit_card.address.city_placeholder')}
                  size="tiny"
                  required={true}
                  {...city}
                />
              </div>
              <div className="credit-card-details__field-state">
                <Input
                  label={I18n.t('ibe.payment.credit_card.address.state')}
                  placeholder={I18n.t('ibe.payment.credit_card.address.state_placeholder')}
                  size="tiny"
                  touched={touched}
                  {...state}
                />
              </div>
            </div>
            <div className="credit-card-details__field-country-zip--wrapper">
              <div className="credit-card-details__field-country">
                <CountrySelectorContainer
                  title={I18n.t('ibe.payment.credit_card.address.country')}
                  placeholder={I18n.t('ibe.payment.credit_card.address.country_placeholder')}
                  touched={touched}
                  required={true}
                  {...country}
                />
              </div>
              <div className="credit-card-details__field-zip-code">
                <Input
                  label={I18n.t('ibe.payment.credit_card.address.zip_code')}
                  touched={touched}
                  placeholder={I18n.t('ibe.payment.credit_card.address.zip_code_placeholder')}
                  required={true}
                  size="tiny"
                  {...zip}
                />
              </div>
            </div>
            <div className="credit-card-details__field-email">
              <Input
                label={I18n.t('ibe.payment.credit_card.address.email')}
                placeholder="example@domain.com"
                size="tiny"
                touched={touched}
                required={true}
                {...email}
              />
            </div>
            <div className="credit-card-details__field-phone">
              <PhoneNumber
                options={this.state.phoneCountryCodes}
                label={I18n.t('ibe.payment.credit_card.address.phone')}
                size="tiny"
                isRequired={true}
                isInputTouched={phoneNumber.touched}
                {...phoneNumber}
                onChange={value => {
                  this.props.fields.cardholderAddress.phoneNumber.onChange({
                    value,
                  });
                }}
                onCountryChange={option => {
                  this.props.fields.cardholderAddress.phoneCountryCode.onChange({
                    value: option.value,
                  });
                }}
                inputValue={phoneNumber.value}
                selectedCountry={findLabelValue(
                  this.state.phoneCountryCodes,
                  phoneCountryCode.value,
                )}
                countryPlaceholder="(+41)"
              />
            </div>
          </div>
          <div className="credit-card-details__buttons">
            <Button
              size="small"
              version="v2"
              className=""
              label={I18n.t('shared.actions.next')}
              primary={true}
              disabled={!!submitting}
              submitType="submit"
            />
            <Button
              size="small"
              version="v2"
              className=""
              label={I18n.t('shared.actions.cancel')}
              primary={false}
              onClick={this.props.toggleModal}
            />
          </div>
          <div>{this.state.error}</div>
        </form>
      );
    }

    return <div className="col-12 col-bleed credit-card-details">{content}</div>;
  }
}

WellDevBookingComponent.defaultProps = {
  touched: false,
};

WellDevBookingComponent.propTypes = {
  ...reduxFormShape,
  saveCreditCardInBackend: PropTypes.func.isRequired,
  fields: PropTypes.shape().isRequired,
  ownersBillingAddress: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    street: PropTypes.string,
    zip: PropTypes.string,
    state: PropTypes.string,
    email: PropTypes.string,
    phoneCountryCode: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
  touched: PropTypes.bool,
  setCardDataAvailableByToken: PropTypes.func.isRequired,
  setShowCardAddedConfirmation: PropTypes.func.isRequired,
};

WellDevBookingComponent.contextTypes = {
  ccvPublicToken: PropTypes.string.isRequired,
  ccvURL: PropTypes.string.isRequired,
  tenantIdentifier: PropTypes.string.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  laymanMode: PropTypes.bool,
};

export default WellDevBookingComponent;
