import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { Link } from '@wtag/react-comp-lib';

import './styles.scss';

const TopStories = ({ blogPosts, listUrl }) => {
  const blogPostCard = blogPost => (
    <div className="top-stories__blog-card" key={blogPost.id}>
      <a href={blogPost.url}>
        {blogPost.featureImage ? (
          <img
            className="top-stories__blog-card-image"
            src={blogPost.featureImage}
            alt="blog-feature"
          />
        ) : (
          <div className="top-stories__blog-card-image top-stories__blog-card-placeholder">
            {I18n.t('admin.unsplash.modal.not_found')}
          </div>
        )}
        <div className="top-stories__blog-card-name">{blogPost.name}</div>
        <div className="top-stories__blog-card-content">{blogPost.content}</div>
      </a>
    </div>
  );

  return (
    <div className="top-stories">
      <div className="top-stories__heading">
        <div className="top-stories__heading-title">
          {I18n.t('public.static_pages.top_stories')}
        </div>
        <hr className="top-stories__hr" />
        <Link href={listUrl} modifier="default">
          {I18n.t('public.static_pages.read_all_stories')}
        </Link>
      </div>

      <div className="top-stories__list">{blogPosts.map(blogPost => blogPostCard(blogPost))}</div>
    </div>
  );
};

TopStories.propTypes = {
  blogPosts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      featureImage: PropTypes.string,
      content: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
  listUrl: PropTypes.string.isRequired,
};

export default hot(module)(TopStories);
