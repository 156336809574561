import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';
import {
  birthdayDriverAgeValidation,
  birthdayValidation,
  childOtherBirthdayAgeValidation,
  presenceValidation,
  validate,
  duplicateValidation,
  transliterateValidation,
  formatValidation,
  emailWithoutSpecialCharacters,
  maxLengthValidation,
  adultBirthdayValidation,
  childrenBirthdayValidation,
  infantBirthdayValidation,
  fullNameLengthValidation,
} from '../validations';
import { NAME_MAX_LENGTH } from '../../../helpers/travellerValidation';
import calculateDateDurations from '../../../helpers/calculateDateDurations';

export const nameCommonValidation = {
  format: {
    pattern: '[^\\d.\\?()=\\+#\\^&%~!\\-$<>\\[\\]{}\\*@_/|;\\\\`:\'",]+|^$',
    message: <I18nText id="ibe.validations.name" />,
  },
};

export const streetCommonValidation = {
  format: {
    pattern: '^[a-zA-Z0-9\\s]*$',
    message: <I18nText id="ibe.validations.street" />,
  },
};

export const cityCommonValidation = {
  format: {
    pattern: '^[a-zA-Z\\s]*$',
    message: <I18nText id="ibe.validations.city" />,
  },
};

export const stateCommonValidation = {
  format: {
    pattern: '^[a-zA-Z\\s]*$',
    message: <I18nText id="ibe.validations.state" />,
  },
};

export const cardNameCommonValidation = {
  format: {
    pattern: '[^\\d\\-?]+|^$',
    message: <I18nText id="ibe.validations.card_name" />,
  },
};

export const phoneNumberCommonValidation = {
  format: {
    pattern: '^\\+?\\d+$|^$',
    message: <I18nText id="ibe.validations.phone_number_invalid_character" />,
  },
};

export const identificationNumberValidation = {
  format: {
    pattern: '^[a-zA-Z0-9\\s]*$',
    message: <I18nText id="ibe.validations.number" />,
  },
};

export const salutationForChildInfant = {
  exclusion: {
    within: ['mrs'],
    message: <I18nText id="ibe.validations.name_prefix" />,
  },
};

export const zipCodeFormatValidation = {
  format: {
    pattern: '^[a-zA-Z0-9\\s-]*$',
    message: <I18nText id="ibe.validations.zip_code" />,
  },
};

const channelMapper = channel => {
  switch (channel.identifier) {
    case 'BA':
      return 'british_airways';
    case 'mango':
      if (channel.account !== 'JE') {
        return 'mango_direct';
      }
      return channel.identifier;
    default:
      return channel.identifier;
  }
};

export const atLeastOneAdultIsAboveSixteen = adultsBirthdays =>
  adultsBirthdays.some(
    birthday => new Date().getFullYear() - new Date(birthday).getFullYear() >= 16,
  );

export const validateDuplicateEmail = (params, travelers) => {
  const isValidationNotRequired =
    typeof params.email === 'undefined' ||
    !params.email ||
    typeof params.contactInformationCopied === 'undefined' ||
    params.contactInformationCopied ||
    travelers.length < 1;

  if (isValidationNotRequired) {
    return null;
  }

  const emailList = travelers.map(traveler => traveler.email);

  const message = <I18nText id="ibe.validations.duplicate_email" />;
  const customValidation = duplicateValidation(params.email, emailList, message);

  const extendedConstraints = {
    email: { ...customValidation },
  };

  return validate(params, extendedConstraints);
};

export const isChannelPresent = (channelName, itemChannels) =>
  itemChannels && itemChannels.some(itemChannel => channelMapper(itemChannel) === channelName);

export const streetLengthValidation = itemChannels => {
  const galileoMaxStreetLength = 250;
  const travelfusionMaxStreetLength = 100;
  const amadeusMaxLength = 48;

  const lengthRestrictions = [];

  if (isChannelPresent('galileo', itemChannels)) {
    lengthRestrictions.push(galileoMaxStreetLength);
  }
  if (isChannelPresent('travelfusion', itemChannels)) {
    lengthRestrictions.push(travelfusionMaxStreetLength);
  }
  if (isChannelPresent('amadeus', itemChannels)) {
    lengthRestrictions.push(amadeusMaxLength);
  }

  return maxLengthValidation(Math.min(...lengthRestrictions));
};

export const validateEmailForGalileo = (params, itemChannels) => {
  let customEmailValidation;
  if (isChannelPresent('galileo', itemChannels)) {
    customEmailValidation = emailWithoutSpecialCharacters;
  }
  const extendedConstraints = {
    email: {
      ...customEmailValidation,
    },
  };

  return validate(params, extendedConstraints);
};

export const validateBirthday = (params, itemChannels, journeyStartDate) => {
  let customBirthdayValidation;
  const { driverAge, birthday } = params;
  const ageDuringJourneyStartDate = calculateDateDurations(birthday, journeyStartDate);
  const ageFromBirthday = new Date().getFullYear() - new Date(params.birthday).getFullYear();
  switch (params.type) {
    case 'adult':
      customBirthdayValidation = adultBirthdayValidation;
      if (driverAge) {
        if (ageFromBirthday < driverAge - 1 || ageFromBirthday > driverAge + 1) {
          customBirthdayValidation = birthdayDriverAgeValidation;
        }
      }
      break;

    case 'child': {
      customBirthdayValidation = childrenBirthdayValidation(ageDuringJourneyStartDate);

      const { childAge, cartHasFlight } = params;

      if (isChannelPresent('mango_direct', itemChannels) || cartHasFlight) break;

      if (childAge) {
        const isWithinRange =
          ageDuringJourneyStartDate >= childAge - 1 && ageDuringJourneyStartDate <= childAge + 1;
        customBirthdayValidation = isWithinRange ? null : childOtherBirthdayAgeValidation(childAge);
      }

      break;
    }

    case 'infant':
      customBirthdayValidation = infantBirthdayValidation(ageDuringJourneyStartDate);
      break;

    default:
      break;
  }

  const extendedConstraints = {
    birthday: {
      ...birthdayValidation,
      ...presenceValidation,
      ...customBirthdayValidation,
    },
  };

  return validate(params, { ...extendedConstraints });
};

export const validateTravelerAddress = (params, laymanMode, itemChannels) => {
  let streetValidation = {
    ...streetCommonValidation,
    ...transliterateValidation,
    ...streetLengthValidation(itemChannels),
  };
  let cityValidation = {
    ...cityCommonValidation,
    ...transliterateValidation,
    ...formatValidation,
    ...maxLengthValidation(30),
  };
  let zipValidation = { ...maxLengthValidation(15), ...zipCodeFormatValidation };
  let stateValidation = {
    ...stateCommonValidation,
    ...maxLengthValidation(30),
  };
  let countryValidation = {};
  const gavePartialAddress = Object.values(params.residenceAddress).some(value => !!value);
  if (laymanMode || gavePartialAddress) {
    streetValidation = { ...presenceValidation, ...streetValidation };
    cityValidation = { ...presenceValidation, ...cityValidation };
    zipValidation = { ...presenceValidation, ...zipValidation };
    stateValidation = { ...stateValidation };
    countryValidation = { ...presenceValidation };
  }
  const customTravelerAddressValidation = {
    street: streetValidation,
    city: cityValidation,
    zip: zipValidation,
    state: stateValidation,
    country: countryValidation,
  };
  return validate(params.residenceAddress, customTravelerAddressValidation);
};

export const validateEmailPresenceForLayman = (params, laymanMode, isTravelerLoggedIn) => {
  let emailValidation = {};
  let travelArrangerEmailValidation = {};
  const { isTravelArrangerEmailUsed } = params;

  if (laymanMode && !isTravelArrangerEmailUsed) {
    emailValidation = { ...presenceValidation };
  } else if (laymanMode && isTravelArrangerEmailUsed && isTravelerLoggedIn) {
    travelArrangerEmailValidation = { ...presenceValidation };
  }

  const customTravelerEmailAddress = {
    email: emailValidation,
    travelArrangerEmail: travelArrangerEmailValidation,
  };
  return validate(params, customTravelerEmailAddress);
};

export const validateSalutation = params => {
  let salutationValidation;

  if (params.type === 'child' || params.type === 'infant') {
    salutationValidation = salutationForChildInfant;
  }
  const extendedConstraints = {
    namePrefix: {
      ...salutationValidation,
    },
  };

  return validate(params, extendedConstraints);
};

export const validateTravelerFullname = params => {
  const { namePrefix, firstName, middleName, lastName } = params;
  const commonValidation = { ...nameCommonValidation, ...transliterateValidation };
  const lengthValidation = {
    ...maxLengthValidation(NAME_MAX_LENGTH),
    ...fullNameLengthValidation(firstName, middleName, lastName, namePrefix),
  };
  const firstNameValidation = {
    ...presenceValidation,
    ...commonValidation,
    ...lengthValidation,
  };
  const middleNameValidation = {
    ...commonValidation,
    ...lengthValidation,
  };
  const lastNameValidation = {
    ...presenceValidation,
    ...commonValidation,
    ...formatValidation,
    ...lengthValidation,
  };

  const fullNameValidation = {
    firstName: firstNameValidation,
    middleName: middleNameValidation,
    lastName: lastNameValidation,
  };

  return validate(params, fullNameValidation);
};
