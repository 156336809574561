import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import debounce from 'throttle-debounce/debounce';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import { validate, orderTitleValidation } from 'sharedWebpack/IBE/lib/validations';
import Tooltip from '@wtag/rcl-tooltip';
import { Spinner } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import BookingModal from './BookingModal/';
import ButtonWithSpinner from './ButtonWithSpinner';
import history from '../../lib/history';
import AncillariesUnavailabilityModal from '../AncillariesUnavailabilityModal';
import {
  PROCEDURE_MAPPING,
  ORDER_STYLES,
  BOOKING_STYLES,
  cartHasItemWithCreditCard,
} from '../helpers/booking';
import './styles.scss';

class AgentBookButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      orderStyle: null,
      orderId: null,
      bookingStyle: null,
      loadIframe: false,
      bookingStepCount: 1,
      unavailableServices: {},
      openUnavailableAncillariesModal: false,
      insufficientWalletBalance: false,
      isCreateQuote: false,
      orderTitle: this.props.cartTitle,
      error: {},
    };
  }

  onModalOpen = (isCreateQuote = false) => {
    const existingOrderId = this.context.callbackParams.order_id;

    this.setState({
      modalOpen: true,
      orderStyle: existingOrderId && ORDER_STYLES.EXISTING,
      orderId: existingOrderId,
      bookingStyle: null,
      loadIframe: false,
      isCreateQuote,
    });
  };

  onModalClose = () => {
    this.setState({
      modalOpen: false,
      bookingStepCount: 1,
      isCreateQuote: false,
    });
  };

  onConfirmInsufficientWalletBalanceModal = () => {
    this.props.resetFormOfPayment().then(() => {
      this.setState({ insufficientWalletBalance: false });
      history.push(`/orders/${this.props.cartId}/payment`);
    });
  };

  onOrderTitleChange = value => {
    const error = validate({ orderTitle: value }, orderTitleValidation);
    this.setState({ error, orderTitle: value });
  };

  setLoadIframe = bookingStyle => {
    if (cartHasItemWithCreditCard(this.props.cartItems)) {
      this.setState({ loadIframe: true, bookingStyle, modalOpen: false }, () => {
        this.createBooking();
      });
    } else {
      this.props.setIsBookingButtonClicked(true);
      this.setState({ bookingStyle, modalOpen: false }, () => {
        this.createBooking();
      });
    }
  };

  handleReject = () => {
    this.props.setIsBookingButtonClicked(false);
    this.setState({
      bookingStyle: null,
      loadIframe: false,
      insufficientWalletBalance: false,
    });
  };

  createBooking = () => {
    this.props.checkCartAvailability().then(response => {
      if (response.insufficientWalletBalance) {
        this.setState({ insufficientWalletBalance: response.insufficientWalletBalance });
      } else if (Object.values(response.unavailableServices).length > 0) {
        this.setState({
          unavailableServices: response.unavailableServices,
          openUnavailableAncillariesModal: true,
        });
      } else if (cartHasItemWithCreditCard(this.props.cartItems)) {
        this.continueBookingByCCV();
      } else {
        this.continueBookingByCash();
      }
    });
  };

  continueBookingByCCV = () => {
    if (this.props.bookingPossible) {
      this.chooseOrderCreationStyle(this.state.bookingStyle).then(this.bookAllItemsWithDebounce);
    }
  };

  continueBookingByCash = () => {
    if (this.props.bookingPossible) {
      this.chooseOrderCreationStyle(this.state.bookingStyle).then(() =>
        this.chooseBookingStyle(this.state.bookingStyle),
      );
    }
  };

  bookAllItems = () => {
    this.props.ccTokensThatNeedToBook.forEach(ccToken => {
      this.props.bookingComponentRefByToken[ccToken].current.submitCreditCardAction();
    });
    setTimeout(() => {
      this.chooseBookingStyle(this.state.bookingStyle);
      this.setState({ loadIframe: false });
    }, this.props.ccTokensThatNeedToBook.length * 2000);
  };

  bookAllItemsWithDebounce = debounce(10000, true, this.bookAllItems);

  handleOrderStyleSelection = orderStyle => {
    this.setState({ orderStyle, bookingStepCount: this.state.bookingStepCount + 1 });
  };

  chooseOrder = order => {
    this.setState({ orderId: order.id });
  };

  chooseOrderCreationStyle = orderCreationStyle => {
    const procedure = PROCEDURE_MAPPING[this.state.orderStyle][orderCreationStyle];
    const { orderId, orderTitle } = this.state;

    return this.props.prepareForBooking({
      procedure,
      orderId,
      orderTitle,
    });
  };

  chooseBookingStyle = bookingStyle => {
    const procedure = PROCEDURE_MAPPING[this.state.orderStyle][bookingStyle];
    this.props.startBooking({ procedure, orderId: this.state.orderId });
    this.setState({ modalOpen: false });
  };

  continueBookingOnUnavailableAncillaries = itemIds => {
    this.props.removeItems(itemIds).then(() => {
      if (cartHasItemWithCreditCard(this.props.cartItems)) {
        this.continueBookingByCCV();
      } else {
        this.continueBookingByCash();
      }
    });
  };

  render() {
    const isCreateQuoteEnable = !this.props.onlyOneItemFromSearch && this.props.showQuoteButton;

    const createOrder = () => {
      this.handleOrderStyleSelection(ORDER_STYLES.NEW);
    };

    const createQuote = (orderStyle = ORDER_STYLES.NEW) => {
      this.props.setIsQuoteButtonClicked(true);
      this.setState({ orderStyle, modalOpen: false }, () => {
        this.chooseOrderCreationStyle(BOOKING_STYLES.QUOTE).then(() => {
          this.chooseBookingStyle(BOOKING_STYLES.QUOTE);
        });
      });
    };

    const setOrderListOrderId = id => {
      if (this.state.isCreateQuote && this.state.bookingStepCount === 2) {
        this.setState({ orderId: id, orderStyle: ORDER_STYLES.EXISTING }, () => {
          createQuote(ORDER_STYLES.EXISTING);
        });
      } else {
        this.setState({ orderId: id, bookingStepCount: this.state.bookingStepCount + 1 });
      }
    };

    const createNewOrder = () =>
      this.state.isCreateQuote ? createQuote(ORDER_STYLES.NEW) : createOrder();

    const header = I18n.t('components.ibe.check_out_progress.step_count', {
      count: this.state.bookingStepCount,
    });

    const bookingModalComponent = () => (
      <BookingModal
        isQuoteButtonClicked={this.props.isQuoteButtonClicked}
        isModalOpen={this.state.modalOpen}
        onModalClose={this.onModalClose}
        modalHeader={header}
        createNewOrder={createNewOrder}
        setOrderListOrderId={setOrderListOrderId}
        createQuote={createQuote}
        orderStyle={this.state.orderStyle}
        orderId={this.state.orderId}
        error={this.state.error}
        onOrderTitleChange={this.onOrderTitleChange}
        orderTitle={this.state.orderTitle}
        loadIframe={this.state.loadIframe}
        payLaterItemsToBeQuoted={this.state.payLaterItemsToBeQuoted}
        disableBooking={this.state.disableBooking}
        setLoadIframe={this.setLoadIframe}
        handleOrderStyleSelection={this.handleOrderStyleSelection}
        createOptionPossible={this.props.createOptionPossible}
        disabled={this.props.disabled}
      />
    );

    const bookNowButton = (
      <Button
        label={
          <Fragment>
            {I18n.t('components.ibe.check_out_progress.buttons.book')}
            {this.props.isBookingButtonClicked && <Spinner size="tiny" bgColor="neutral" />}
          </Fragment>
        }
        version="v2"
        size="small"
        primary={!isCreateQuoteEnable}
        onClick={() => this.onModalOpen()}
        disabled={this.props.disabled || isCreateQuoteEnable || this.props.isBookingButtonClicked}
        className="book-buttons__button-contents-button"
      />
    );

    return (
      <div className="book-buttons">
        <AncillariesUnavailabilityModal
          unavailableServices={this.state.unavailableServices}
          modalOpen={this.state.openUnavailableAncillariesModal}
          cartId={this.props.cartId}
          fetchJourneyElement={this.props.fetchJourneyElement}
          onContinueBooking={this.continueBookingOnUnavailableAncillaries}
          removeItems={this.props.removeItems}
        />
        <ConfirmationModal
          confirmationHeader={I18n.t(
            'components.ibe.check_out_progress.insufficient_wallet_balance',
          )}
          subHeader={I18n.t('components.ibe.check_out_progress.texts.insufficient_wallet_balance')}
          type="danger"
          withAction={true}
          confirmationText={I18n.t('admin.shared.action.confirm')}
          rejectionText={I18n.t('admin.shared.action.close')}
          onConfirm={this.onConfirmInsufficientWalletBalanceModal}
          onReject={this.handleReject}
          isModalOpen={this.state.insufficientWalletBalance}
        />
        <div className="d-flex align-center justify-end book-buttons__button-contents">
          <Button
            version="v2"
            size="small"
            primary={isCreateQuoteEnable}
            label={
              <ButtonWithSpinner
                text={I18n.t('components.ibe.check_out_progress.buttons.quote')}
                isLoading={this.props.isQuoteButtonClicked}
              />
            }
            onClick={() => this.onModalOpen(true)}
            disabled={this.state.loadIframe || this.props.isBookingButtonClicked}
            icon={<Icon name="addOrder" />}
            className="book-buttons__button-contents-button"
          >
            {bookingModalComponent()}
          </Button>

          {isCreateQuoteEnable ? (
            <Tooltip
              className="book-buttons__tooltip"
              content={I18n.t('components.ibe.book_quote.only_one_item_per_search')}
              position="bottom-right"
              type="inverse"
              showArrow={false}
            >
              {bookNowButton}
            </Tooltip>
          ) : (
            bookNowButton
          )}
          {bookingModalComponent()}
        </div>
      </div>
    );
  }
}

AgentBookButton.defaultProps = {
  isBookingButtonClicked: false,
};

AgentBookButton.propTypes = {
  cartItems: PropTypes.shape([]).isRequired,
  onlyOneItemFromSearch: PropTypes.bool.isRequired,
  cartTitle: PropTypes.string.isRequired,
  cartId: PropTypes.string.isRequired,
  bookingPossible: PropTypes.bool.isRequired,
  checkCartAvailability: PropTypes.func.isRequired,
  ccTokensThatNeedToBook: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
  startBooking: PropTypes.func.isRequired,
  prepareForBooking: PropTypes.func.isRequired,
  createOptionPossible: PropTypes.bool.isRequired,
  isBookingButtonClicked: PropTypes.bool,
  setIsBookingButtonClicked: PropTypes.func.isRequired,
  bookingComponentRefByToken: PropTypes.node.isRequired,
  fetchJourneyElement: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired,
  resetFormOfPayment: PropTypes.func.isRequired,
  showQuoteButton: PropTypes.bool.isRequired,
  isQuoteButtonClicked: PropTypes.bool.isRequired,
  setIsQuoteButtonClicked: PropTypes.func.isRequired,
};

AgentBookButton.contextTypes = {
  callbackParams: PropTypes.shape({
    order_id: PropTypes.string,
  }).isRequired,
};

export default AgentBookButton;
