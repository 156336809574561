import routes from 'agentRoutes';

export const displayColumns = {
  id: true,
  title: true,
  destination: true,
  travellers: true,
  products: true,
  state: true,
  total: true,
  startDate: true,
  endDate: true,
  actions: false,
  selectAction: true,
};

export const urlsPath = {
  new: routes.admin.newOrder(),
};

export const ordersPath = routes.admin.orders();

export const displaySortingPills = {
  id: true,
  destination: true,
  lastUpdated: false,
  startDate: true,
};
